.AppContainer{
   max-width: calc(100% - 100px);
   margin: auto;
}
.navContainer {
  display: flex;
  width: fit-content;
}
.title {
  font-size: 25px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.item {
  flex-grow: 1;
  width: -webkit-fill-available;
  margin: 4px 8px;
}
.item:first-child{
  padding-left: 0px;
}

.navText{
  text-decoration: none;
  div{
  color: #8D8D8D;
  text-decoration: none;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  }
}
.navActive{
  text-decoration: none;
  div{
  border-width: 3px;
  border-bottom-color:#FF7448;
  border-bottom-style: solid;
  }
  color: #fff;
}

.rowReverse{
  display: flex;
  flex-direction: row-reverse;
}

.m10{
  margin-top: 10px;
}
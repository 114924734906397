.toggleButtonContainer {
  border-style: groove;
  display: flex;
  width: fit-content;
  border-width: 1px;
  border-radius: 4px;
  border-color: white;
}
.toggleButton {
  font-size: 14px;
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: 0.5px;
  font-family: "Roboto Slab", serif;
  height: 35px;
  padding: 0px 25px;
}

.active {
  background-color: orangered;
  border-radius: 4px;
}

@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,600;0,700;1,500&family=Poppins:wght@300;400;500;600;700;800&family=Roboto+Slab:wght@500&display=swap");
.overlay {
  display: grid;
}
.bettics {
  color: aliceblue;
  font-family: Roboto Slab;
  font-size: 2.8rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-align: center;
}
.dot {
  font-size: 52px;
  font-weight: 500;
  line-height: 69px;
  letter-spacing: 0.04em;
  color: #ff7448;
}
.loginContainer {
  margin: auto;
  padding: 30px;
  border-radius: 8px;
  border: 1px;
  gap: 64px;
  color: white;
  border-color: #8d8d8d;
  border-style: solid;
  text-align: center;
}
.loginMsg {
  font-family: Roboto Slab;
  font-size: 28px;
  font-weight: 500;
  line-height: 37px;
  letter-spacing: 0.04em;
  margin: auto;
  color: white;
}
.entere {
  color: azure;
  font-family: Roboto Slab;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
}
.email {
  margin-top: 2.5rem;
  line-height: 0;
  width: 500px;
}
.em {
  width: -webkit-fill-available;
  height: 50px;
  top: 161px;
  left: 39px;
  color: aliceblue;
  background-color: transparent;
  border-color: #8d8d8d;
  border-style: solid;
  border-radius: 2px;
  border-width: 1px;
  font-family: Roboto Slab;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 5px;
  padding-left: 10px;
}
.inputEm:focus {
  border-bottom: 3px solid #ff7448;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}
 .login{
    margin-top: 2rem !important;
    width: -webkit-fill-available;
    background-color: #ff7448 !important;
 }
.or {
  font-family: Roboto Slab;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: beige;
}
.google {
  width: 226px;
  height: 48px;
  top: 360px;
  left: 39px;
  border-radius: 4px;
  border: 0.5px;
  gap: 10px;
  background-color: transparent;
  border: 0.5px solid #8d8d8d;
  cursor: pointer;
  &:hover {
    background-color: white;
  }
}

.loginWith {
  display: flex;
  justify-content: space-between;
}
.apple {
  width: 226px;
  height: 48px;
  top: 360px;
  left: 39px;
  border-radius: 4px;
  border: 0.5px;
  background-color: transparent;
  border: 0.5px solid #8d8d8d;
  cursor: pointer;
  &:hover {
    background-color: white;
  }
}

.asvg {
  width: 24px;
  height: 24px;
}

.apple:hover .asvg {
  fill: black;
}

@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,600;0,700;1,500&family=Poppins:wght@300;400;500;600;700;800&family=Roboto+Slab:wght@500&display=swap");
.insights {
  .searchbar {
    background-color: black;
    padding: 10px;
    border-style: solid;
    border-color: #444;
    border-radius: 5px;
    width: 300px;
  }

  .search {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 50px;
    margin-left: 3%;
  }

  .sb {
    position: absolute;
    top: 182px;
    left: 60px;
  }

  /* Custom Toggle Container */
  .custom-toggle-container {
    display: flex;
    border: 2px solid black;
    border-radius: 5px;
    width: 20%;
    padding: 2px;
    padding-bottom: 10px;
    margin-left: 10%;
    margin-top: -2.3%;
  }

  /* Custom Toggle Buttons */
  .custom-toggle-button {
    background-color: black;
    color: white;
    font-weight: lighter;
    border-radius: 5px;

    outline: none;
    cursor: pointer;
    padding: 5px;
    margin-right: 0.5px;
    margin-left: 2px;
    height: 30px;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto Slab", serif;
    transition: background-color 0.3s;
    border-style: solid;
    border-color: #444;
    width: 100px;
    margin-right: 5px;
  }

  /* Active Toggle Button */
  .custom-toggle-button.active {
    background-color: orangered;
  }

  .option_btn {
    display: flex;
  }

  .custom-toggle-container {
    margin-left: 52%;
  }
}

.CardItemContainer{
  margin-top: 1.725rem;
}

.centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 500px);
  text-align: center;
  font-weight: 500;
  font-size: 50px;
  color: rgb(240, 240, 103);
  letter-spacing: 7px;
}
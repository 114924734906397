@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,600;0,700;1,500&family=Poppins:wght@300;400;500;600;700;800&family=Roboto+Slab:wght@500&display=swap");
.app {
  width: 500px;
  height: 400px;
  border-radius: 8px;
  border: 1px;
  margin: auto;
  margin-top: 10%;
  gap: 64px;
  display: flex;
  flex-wrap: nowrap;
  column-gap: 5px;
  justify-content: center;
  align-items: center; /* Center vertically */
  flex-direction: column;
  border-color: #8d8d8d;
  border-style: solid;
  border-radius: 10px;
  /* Set the background color to red */
  background-color: #171717;
  padding: 20px;
  padding-bottom: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  z-index: 1000;
}

.otptext {
  font-family: Roboto Slab;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.04em;
  color: white;
  margin-bottom: -10%;
}
.digitGroup {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}
.etext {
  font-family: Roboto Slab;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #8d8d8d;
  margin-top: 6%;
  margin-bottom: -10%;
}

.digitGroup input {
  outline: 0 !important;
  user-select: none !important;
  width: 50px;
  height: 50px;
  background-color: transparent;
  font-family: Roboto Slab;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;

  border-radius: 5px;
  border-color: #8d8d8d;
  border-style: solid;

  text-align: center;

  color: white;
  margin: 0;
}

.resend {
  margin-top: -16%;
  margin-left: 63%;
  color: #8d8d8d;
  margin-bottom: -8%;
}
.resend:hover {
  color: #ff7448;
}
.digitGroup input:focus {
  border: 2px solid white;
}

.digitGroup input:active {
  border: 2px solid;
}

.digitGroup .splitter {
  padding: 5px 0;
  color: rgb(0, 0, 0);
  font-size: 25px;
  margin: 0;
}

.prompt {
  margin-bottom: 20px;
  font-size: 20px;
  color: white;
}

.formSection {
  max-width: 500px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0px 0px 8px #ddd;
  padding: 20px;
}

.w-100 {
  width: 100%;
}

.btnGroup {
  text-align: center;
}

.form {
  padding: 20px;
  max-width: 500px;
  margin: auto;
  box-shadow: 0px 0px 8px #ddd;
  border-radius: 10px;
}

.h2 {
  text-align: center;
}

.button {
  color: #ffffff;
  width: 380px;
  height: 48px;
  top: 260px;
  left: 60px;
  padding: 8px, 32px, 8px, 32px;
  border-radius: 4px;
  border: 1px;
  gap: 10px;

  background-color: #ff7448;
  font-family: Roboto Slab;
  font-size: 20px;
  font-weight: 500;

  letter-spacing: 0em;
  text-align: center;

  cursor: pointer;
}
.button:hover {
  color: black;
  background-color: #ffffff;
}
/* Media query for mobile devices */
@media (max-width: 480px) {
  .digitGroup {
    gap: 5px !important;
  }

  .digitGroup input {
    width: 40px !important;
  }

  .digitGroup .splitter {
    font-size: 25px !important;
  }
}

.main{
    color: white;
    padding: 30px;
  }
  
  .arrowBtn{
    margin-right: 36vw;
    font-weight: 400;
    font-size: 16px;
    font-family: Roboto Slab;
  }
  
  .arrowBtn:hover{
    transform: scale(1.02);
    cursor: pointer;
    color: #FF7448;
  }
  
  .arrowBtn:hover path {
    fill: #FF7448;
  }
  
  .header{
    display: flex;
    flex-direction: row;
    margin-bottom: 7vh;
  }
  
  .sport{
    font-size: 24px;
    font-weight: 700;
    font-family: Roboto Slab;
  }
  
  .squadplayers{
    font-weight: 600;
    font-size: 20px;
    font-family: Roboto Slab;
    text-decoration: underline;
    text-decoration-color: white;
    margin-bottom: 3vh;
  }
  
  .squads{
    display: flex;
  }
  
  .squad{
    margin-right: 15vw;
  }
  
  .teamName{
    font-family: Roboto Slab;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: center;
  }
  
  .box {
    border-style: solid;
    border-color: #FF7448;
    padding-left: 70px;
    padding-top: 40px;
    padding-bottom: 40px;
    height: fit-content;
    width: fit-content;
    display: grid;
    grid-template-columns: repeat(3, 10vw);
    grid-gap: 10px;
  }
  
  .pname{
    font-size: 0.5rem;
  }
  
  .item{
  height: fit-content;
  padding: 7px;
  width: fit-content;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 3px;
  }
  .guard{
    color: white;
    font-size: 0.3rem;
  }
  .item:hover{
    background-color: rgba(224, 224, 224, 0.123);
  }
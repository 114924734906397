.CardItemContainer{
   background-color: #262626;
   border-radius: 4px;
   display: grid;
   grid-template-columns: auto 150px;
   padding: 0.75rem 1rem;
   color: #FFFFFF;
   margin-bottom: 1.5rem;
}
.CardRightContainer{
  display: flex;
  height: fit-content;
  align-items: flex-end;
  div{
    margin: auto;
  }
}
.CardLeftContainer{
    .ProfileContainer{
      display: grid;
      grid-template-columns: 60px auto;
      span{
        color: #8D8D8D;
        display: block;
        font-size: 14px;
      }
    }
    .ContentContainer{
      margin-top: 5px;
    }
}
.teamnameContainer{
    border-radius: 5px;
    border-style: solid;
    border: 0.5px solid #8d8d8d;
    border-width: 1px;
    padding: 5px;
    padding: 5px;
    margin: auto ;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-evenly;;
    max-width: fit-content;
    span{
      margin-left: 10px;
    }
  }
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,600;0,700;1,500&family=Poppins:wght@300;400;500;600;700;800&family=Roboto+Slab:wght@500&display=swap'); 
.toggleContainer{
    display: flex;
  
  }
  
  .toggleButton {
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: Roboto Slab;
    font-weight: 18px;
    font-size: 0.8rem;  
    border-color: #8D8D8D;
    border-style: solid;
    border-width: 0.8px;
  }
  
  .activeToggleButton{
    background-color: #FFFFFF;
    color: black;
  }
  
  .toggleButton2{
    background-color: black;
    color: #FFFFFF4D;
    border: none;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    font-family: Roboto Slab;
    font-weight: 18px;
    font-size: 0.8rem;  
    border: none;
  }
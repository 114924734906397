.searchform {
    background-color: black;
    padding: 10px;
    border-style: solid;
    border-color: #444;
    border-radius: 5px;
    width: 270px;
  }

.searchbar {
    background-color: black;
    border: none;
    margin-left: 30px;
    width: 220px;
    color: white; // Changed font color to white in search input
  }
  
  .search {
    position: relative;
    top: 0;
    left: 0;
    margin-top: 1%;
    margin-left: 0%;
  }
  
  .sb {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
  }
@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,600;0,700;1,500&family=Poppins:wght@300;400;500;600;700;800&family=Roboto+Slab:wght@500&display=swap");
.table-container {
    background-color: white;
  }
  .table {
    border-collapse: collapse;
    width: -webkit-fill-available;
    margin-top: 2.2rem;
    th,
    td {
      border: 1px solid #3B3B3B;
      text-align: center;
      padding: 10px;
 
    }
  
    th {
      background-color: #0e0c0c;
      color: white;
      font-family: Roboto Slab;
font-size: 13px;

line-height: 21px;
letter-spacing: 0em;


    }
  
    td {
      background-color: #262626;
      color: white;
      font-family: Roboto Slab;
font-size: 12px;
line-height: 21px;
letter-spacing: 0em;


    }
  }
  
  .fixed-row {
    height: 41px;
    font-family: "Roboto Slab";
    font-size: 16px;
    font-weight: lighter;
  }
  
  .hug-row {
    height: 152px;
  }
  
  .Box {
    border-radius: 5px;
    border-style: solid;
    border: 0.5px solid #8d8d8d;
    border-width: 1px;
    padding: 5px;
    margin-bottom: 2px;
  }
  .Boxx {
    margin: 2px;
    border-radius: 5px;
    border-style: solid;
    border: 0.5px solid #8d8d8d;
    border-width: 1px;
    padding: 5px;
  }
  .Box1 {
    margin: 2px;
    border-radius: 5px;
    border-style: solid;
    border: 0.5px solid #8d8d8d;
    border-width: 1px;
    padding: 5px;
    border-color: #5dff80;
    color: #5dff80;
  }
  .Box2 {
    margin: 2px;
    border-radius: 5px;
    border-style: solid;
    border: 0.5px solid #8d8d8d;
    border-width: 1px;
    padding: 5px;
    border-color: #ff6a6a;
    color: #ff6a6a;
  }
  .pointspread {
    display: flex;
    justify-content: center;
  }
  
.CardSquareItemContainer {
}
.ImageContainer {
  max-width: 350px;
  max-height: 260px;
}

.TextContainer {
  padding: 30px 0px;
  max-width: calc(100% - 20px);
  margin: auto;
  color: white;
}

.descriptionText {
}
.InfoTextContainer {
  display: flex;
}

.timeText {
}
.linkText {
}

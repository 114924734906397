@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,600;0,700;1,500&family=Poppins:wght@300;400;500;600;700;800&family=Roboto+Slab:wght@500&display=swap");

.match {
}

.table-container {
  background-color: white;
}
.table {
  border-collapse: collapse;
  width: -webkit-fill-available;
  margin-top: 2.2rem;
  th,
  td {
    border: 1px solid black;
    text-align: center;
    padding: 10px;
  }

  th {
    background-color: #3b3b3b;
    color: white;
  }

  td {
    background-color: #262626;
    color: white;
  }
}

.fixed-row {
  height: 41px;
  font-family: "Roboto Slab";
  font-size: 16px;
  font-weight: lighter;
}

.hug-row {
  height: 152px;
}

.FlexConatiner{
  margin: auto;
  width: fit-content;
  display: flex;
  .Box{
    margin-left: 4px;
  }
}

.Box {
  border-radius: 5px;
  border-style: solid;
  border: 0.5px solid #8d8d8d;
  border-width: 1px;
  padding: 5px;
  margin: auto ;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-evenly;;
  width: max-content;
  span{
    margin: auto;
    width: 2.5rem;
  }
  cursor: pointer;
}
.teamnameContainer{
  padding: 5px;
  margin: auto ;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-evenly;;
  max-width: fit-content;
  span{
    margin-left: 10px;
  }
}
.Boxx {
  margin: 2px;
  border-radius: 5px;
  border-style: solid;
  border: 0.5px solid #8d8d8d;
  border-width: 1px;
  padding: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-evenly;;
  span{
    margin: auto;
    width: 2.5rem;
  }
}
.Box1 {
  margin: 2px;
  border-radius: 5px;
  border-style: solid;
  border: 0.5px solid #8d8d8d;
  border-width: 1px;
  padding: 5px;
  border-color: #5dff80;
  color: #5dff80;
  width: 36px;
}
.Box2 {
  margin: 2px;
  border-radius: 5px;
  border-style: solid;
  border: 0.5px solid #8d8d8d;
  border-width: 1px;
  padding: 5px;
  border-color: #ff6a6a;
  color: #ff6a6a;
  width: 36px;
}
.pointspread {
  display: flex;
  justify-content: center;
}
.L30{
  background-color: #B45555 !important;
}




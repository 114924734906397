@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,600;0,700;1,500&family=Poppins:wght@300;400;500;600;700;800&family=Roboto+Slab:wght@500&display=swap');

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #222222;
  color: white;
  margin-bottom: 40px;
  width:100%;
}

.company-name {
  margin-left: 12px;
  font-size: 25px;
  font-weight: 1px;
  font-family: 'Roboto Slab', serif;
  margin-right: 400px;

  &::after {
    content: "\25A0";
    font-size: 8px;
    color: white;
  }
}
.box{
  border-color:  #FFFFFF;
  border-style: solid;
  border-width: 0.009px;
  border-radius: 1px;
  padding: 5px;
}
.sort{
  margin-left: 70%;
}




